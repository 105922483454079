















import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class DescriptionItem extends Vue {
  @Prop({
    required: true,
    type: Number,
  }) readonly count!: number

  @Prop({
    required: true,
    type: String,
  }) readonly title!: string
}
